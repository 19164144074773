<template>
  <td class="px-4 py-1">
    <span v-if="showedInChips.includes(itemKey)">
      <template v-if="isChipValueEmpty"> {{ itemValue }}</template>
      <v-chip v-else-if="itemValue[0] || itemKey === 'inputeFileName'" class="mr-3" color="#ddd">
        <v-tooltip :disabled="!isTooltipActivated(`${itemKey}ChipItem`)" top max-width="200">
          <template v-slot:activator="{ on }">
            <span 
              v-on="on" 
              class="text-truncate"
              @mouseover="activateTooltip($event, `${itemKey}ChipItem`)"
            >{{ getChipText() }}</span>
            <v-icon v-if="showReferenceIcon" color="var(--secondary)" size="18">{{ "mdi-star" }}</v-icon>
          </template>
          <span class="chip__tooltip">{{ getChipText() }}</span>
        </v-tooltip>
      </v-chip>
      <v-tooltip v-if="Array.isArray(itemValue) && itemValue.length > 1" top max-width="200">
        <template v-slot:activator="{ on }">
          <span v-on="on" class="chip__caption mt-1">
            (+{{ itemValue.length - 1 }} {{ $tc(messagesFieldMap[itemKey], itemValue.length - 1) }})
          </span>
        </template>
        <span class="chip__tooltip">{{ getTooltipText() }}</span>
      </v-tooltip>
    </span>
    <template v-else-if="Array.isArray(itemValue)">
      <span v-for="(val, index) in itemValue" :key="index">
        {{ val }}
        {{ index !== itemValue.length - 1 ? ", " : "" }}
      </span>
    </template>
    <template v-else>
      <span v-if="itemKey === 'user'" class="user-mail">{{ itemValue.email }}</span>
      <span v-else-if="!!itemValue">{{ itemValue }}</span>
    </template>
  </td> 
</template>
<script>
  export default {
    name: 'ProcessInfoCell',
    props: {
      itemKey: {
        type: String,
      },
      itemValue: {
        type: [String, Object, Array],
      },
      isReferenceFile: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showedInChips: ["selectedFilterNames", "primaryKeyColumns", "finalColumns", "outputDefinitionNames", "variables", "inputFileName"],
        keyValueChips: ["variables"],
        messagesFieldMap: {
          "selectedFilterNames": "processHistory.filter",
          "primaryKeyColumns": "processHistory.column",
          "finalColumns": "processHistory.column",
          "outputDefinitionNames": "processHistory.outputDefinitions",
          "variables": "processHistory.variables"
        },
        tooltipActivated: [],
      };
    },
    computed: {
      isChipValueEmpty() {
        return !this.itemValue || this.itemValue === '--';
      },
      showReferenceIcon() {
        return this.isReferenceFile === true && this.itemKey === 'inputFileName'
      }
    },
    methods: {
      activateTooltip(event, ref) {
        const { target } = event;
        this.$set(
          this.tooltipActivated,
          ref,
          target?.offsetWidth < target?.scrollWidth
        );
      },
      formatKeyedValues(keyedValue) {
        if (!keyedValue?.key) return;
        return `${keyedValue.key}: ${keyedValue.value}`;
      },
      getChipText() {
        if (!Array.isArray(this.itemValue)) return this.itemValue;
        if (this.keyValueChips.includes(this.itemKey)) return this.formatKeyedValues(this.itemValue[0]);
        return this.itemValue[0];
      },
      getTooltipText() {
        if (!Array.isArray(this.itemValue)) return this.itemValue;
        const values = this.itemValue.slice(1);
        if (this.keyValueChips.includes(this.itemKey)) return values.map(val => this.formatKeyedValues(val)).join(', ');
        return values.join(', ');
      },
      isTooltipActivated(ref) {
        return this.tooltipActivated[ref];
      },
    }
  }
</script>
<style scoped lang="scss">
  
  .user-mail {
    color: var(--link) !important;
    text-decoration: underline;
  }
  .mdi-star {
    margin-left: 10px;
  }
  .v-chip{
    color: var(--fontColorSecondary);
  }

  .chip {
    &__caption {
      color: #777777;
      cursor: pointer;
      font-size: 14px;
      font-style: italic;
      margin: .2em;
    }
    &__tooltip {
      word-wrap: break-word;
    }
  }
</style>