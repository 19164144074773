<template>
  <div class="lastProcess-card">
    <section class="lastProcess-card__header">
      <div class="lastProcess-card__header__box">
        <v-icon class="lastProcess-card__header__icon" color="var(--primary)"
          >mdi-clipboard-text</v-icon
        >
        <span class="lastProcess-card__header__title">{{
          $t("lastProcess")
        }}</span>
      </div>
      <v-icon class="lastProcess-card__header__closeIcon" @click="closeCard"
        >mdi-close</v-icon
      >
    </section>
    <div class="lastProcess-card__list-container">
      <ProcessList
        v-if="isLoading || (dataComponent && dataComponent.length)"
        :dataComponent="dataComponent"
        :loadingParent="isLoading"
        :hidePagination="true"
        :showFileInfo="false"
        :hideActions="true"
        :showDetailButton="true"
        class="lastProcess-card__processList"
      ></ProcessList>
      <div v-else class="lastProcess-card__no-data">
        <span>{{ $t("noData.ProcessHistory") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ProcessList from "@/components/ProcessList";
import { mapState } from "vuex";

export default {
  name: "LastProcess",
  components: {
    ProcessList,
  },
  computed: {
    ...mapState(["processData", "loadingPage"]),
    isLoading() {
      return this.loadingPage > 0;
    },
    dataComponent() {
      return this.processData?.slice(0, 5);
    },
  },
  methods: {
    closeCard() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.lastProcess-card {
  width: 455px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  &__no-data {
    margin-top: -10px;
    height: calc(100% - 16px);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 0px #0000001a;
    border-radius: 10px;
    margin: 8px;

    span {
      font-size: 20px;
      font-weight: 500;
      color: var(--lightGray);
      text-align: center;
      padding: 0px 20px;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__box {
      display: flex;
      gap: 8px;
    }
    &__title {
      font-size: 20px;
      color: var(--fontColor);
    }

    &__closeIcon {
      color: var(--fontColor);
    }
  }
  &__list-container {
    overflow-y: auto;
    width: 100%;
    // 385px = 300px height of parent card + 85px height of title and paddings
    height: calc(100vh - 385px);
    max-height: 445px !important;
    ::v-deep(.container-list) {
      margin-top: 0!important
    }
  }
  &__processList {
    width: 100%;
    height: 100%;
    overflow: auto;
    ::v-deep(.container-list .card-list:not:last-child) {
      margin-bottom: 15px !important;
    }

    ::v-deep(.v-card:first-child) {
      margin-top: 2px !important;
    }
    ::v-deep(.container-list .card-list:not:last-child) {
      margin-bottom: unset;
    }
    ::v-deep(.d-flex.align-center.gap) {
      display: flex;
      align-items: center;
      gap: 5px;
      min-width: 0;
    }
    ::v-deep(.container-list .card-list:last-child) {
      margin-bottom: 0px !important;
    }
    ::deep(.container-list .card-list .v-card) {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    }
    ::v-deep(.v-avatar img) {
      width: 28px;
      height: 28px;
    }
    ::v-deep(.mdi-account-circle::before) {
      font-size: 32px;
    }
    ::v-deep(.statusIcon) {
      padding: 3px 5px 3px 5px;
      gap: 3px;
    }
    ::v-deep(.status .percentage) {
      font-size: 14px;
      padding-right: 0px;
    }
    ::v-deep(.statusIcon .v-icon) {
      width: 24px;
      height: 24px;
    }
    ::v-deep(.status > div) {
      flex-wrap: nowrap !important;
      gap: 3px !important;
    }

    ::v-deep(.headerItem) {
      font-size: 16px;
      color: var(--fontColor);
    }
    ::deep(div .headerItem .v-application .flex-grow-1) {
      -webkit-box-flex: unset !important;
      -ms-flex-positive: unset !important;
      flex-grow: unset !important;
    }

    ::v-deep(.v-expansion-panel-header) {
      padding: 16px;
      width: 100%;
      div {
        max-width: 400px;
      }
    }
    ::v-deep(.v-expansion-panel-header--active) {
      padding: 16px 16px 0px 16px;
    }
    ::v-deep(.table-container) {
      border: none !important;
      margin-top: 0px;
      padding-left: 8px;
    }
    ::v-deep(.v-expansion-panel-content__wrap) {
      padding: 12px 16px 16px 16px;
    }
    ::v-deep(
        .v-expansion-panel-content__wrap .table-container .v-data-table td
      ) {
      border-bottom: none !important;
      
    }
    ::v-deep(.v-data-table__wrapper) {
      border: none !important;
      
    }
    ::v-deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr > td) {
      padding: 0px 5px 0px 0px;
      text-align: left;
      width: unset;
      font-size: 12px;
      height: unset;
      white-space: nowrap;
      
    }
    ::v-deep(.pb-3, .pt-3) {
      padding: unset;
    }
    ::v-deep(.table-container tr .text-left) {
      font-size: 14px !important;
      vertical-align: text-top;
      color:var(--fontColorTerciary);
    }
    ::v-deep(
        .v-expansion-panel-content__wrap
          .table-container
          .v-data-table
          tr:first-child
      ) {
      display: block;
      margin-bottom: 12px;
    }
    ::v-deep(
        .v-expansion-panel-content__wrap .table-container .v-data-table tr
      ) {
      display: block;
      margin-bottom: 10px;
      min-height: 16px;
      height: auto;
    }
    ::v-deep(
        .v-expansion-panel-content__wrap
          .table-container
          .v-data-table
          tr:last-child
      ) {
      margin-bottom: 0px;
    }
    ::v-deep(
        .v-expansion-panel-content__wrap
          .table-container
          .v-data-table
          tr
          > td:last-child
      ) {
      overflow: hidden;
      padding: 0px !important;
      ul {
        flex-direction: row;
        flex-wrap: wrap;
        vertical-align: top;
        row-gap: 5px;

        .v-chip {
          height: 24px;
          color: var(--fontColorSecondary);
          font-size: 14px;
        }
      }
    }
  }
  :deep(.v-skeleton-loader) {
    height: 68px;
    padding: 16px;
  }
  :deep(section .v-card:last-child) {
    margin-bottom: 0px !important;
  }
}
</style>
