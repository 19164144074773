<template>
  <div class="containerDateDecimalTenorForm">
    <Form
      class="dateDecimalTenorForm"
      :fields="fieldsDateTenorDecimal"
      @updatedForm="
        getStatusComponent = false;
        update($event);
      "
      :globalValues="globalValues"
      :getStatus="getStatusComponent"
      :currentDataValues="currentDataValues"
      :submitForm="submitFormComponent"
      @formStatus="formSubmitted"
      :resetForm="resetForm"
      :returnAlerts="true"
      primaryColor="var(--secondary)"
    >
      <template v-slot:datePattern="{ field, currentValues, onChange }">
        <v-combobox
          :items="field.dataSource"
          :label="field.label"
          :id="field.id"
          ref="combobox"
          :rules="getValidations(field, currentValues)"
          v-model="currentValues[field.name]"
          @change="onChange()"
          @keydown="($event)=> { currentValues[field.name] = $event.target.value; onChange() }"
          clearable
        ></v-combobox>
      </template>
    </Form>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  name: "DateDecimalTenorForm",
  data: () => ({
    getStatusComponent: false,
    dirty: false,
  }),
  props: {
    globalValues: { type: Array },
    currentDataValues: { type: Object },
    submitFormComponent: { type: Boolean },
    resetForm: { type: Boolean },
    dataSource: { type: Object },
    getStatus: { type: Boolean },
  },
   watch: {
    submitFormComponent(v) {
      this.getStatusComponent = v;
      this.$emit("dirty", this.dirty);
    },
    getStatus(v) {
      this.getStatusComponent = v;
      this.$emit("dirty", this.dirty);
    }
   },
  methods: {
    formSubmitted(event) {
      if (this.dirty !== event.dirty && event.dirty) {
        this.dirty = event.dirty;
        this.$emit("dirty", event.dirty);
      }
      this.getStatusComponent = false;
    },
    update(event) {
      let data = {};
      const { datePattern, decimalSeparator, tenorPattern } = event || {}
      if (decimalSeparator) data.decimalSeparator = decimalSeparator;
      else if (datePattern) data.datePattern = datePattern;
      else if (tenorPattern) data.tenorPattern = tenorPattern;
      else data = null;
      const valid = [datePattern, decimalSeparator, tenorPattern].filter(Boolean).length <= 1
      this.$emit("isValidForm", { valid });
      this.$emit("update", data);
    },
    notAvailableFieldsSetTogether(value, currentValues, { field, msg }){
      const areRelatedFieldsEmpty = Array.isArray(field) ? field.some(field => !!currentValues[field]) : !!currentValues[field];
      if (value && areRelatedFieldsEmpty) {
        return msg
      }
      return true
    },
    getValidations(field, currentValues){
      const { name, validators } = field;
      const validation = Object.keys(validators).map(validationFn => this[validationFn](currentValues[name], currentValues, validators[validationFn]));
      return validation;
    }
  },
  computed: {
    fieldsDateTenorDecimal() {
      let fields = [
        {
          name: "datePattern",
          label: i18n.t("datePattern"),
          fieldType: 'custom',
          dataSource: this.dataSource.dateFormat,
          validators: {
            notAvailableFieldsSetTogether: {
              field:  ["decimalSeparator", "tenorPattern"],
              msg: i18n.t("onlyAvailableOneOfValues"),
            },
          },
          info: {
            color: "var(--primary)",
            text: i18n.t("datePatternTooltipOutput"),
            maxWidth: 200,
          },
        },
        {
          name: "decimalSeparator",
          label: i18n.t("decimalSeparator"),
          fieldType: "selectBox",
          dataSource: this.dataSource.defaultDecimalSeparator,
          validators: {
            notAvailableFieldsSetTogether: {
              field:  ["tenorPattern", "datePattern"],
              msg: i18n.t("onlyAvailableOneOfValues"),
            },
          },
          info: {
            color: "var(--primary)",
            text: i18n.t("decimalSeparatorTooltipOutput"),
            maxWidth: 200,
          },
        },
        {
          name: "tenorPattern",
          label: i18n.t("tenorPattern"),
          fieldType: "selectBox",
          dataSource: this.dataSource.tenorPattern,
          validators: {
            notAvailableFieldsSetTogether: {
              field:  ["decimalSeparator", "datePattern"],
              msg: i18n.t("onlyAvailableOneOfValues"),
            },
          },
          info: {
            color: "var(--primary)",
            text: i18n.t("tenorPatternTooltipOutput"),
            maxWidth: 200,
          },
        },
      ];
      return fields;
    },
  },
};
</script>

<style lang="scss" scoped>
.containerDateDecimalTenorForm {
  .dateDecimalTenorForm {
    padding-bottom: 10px;
  }
  ::v-deep .row >div {
    margin-top: 12px;
  }
}
</style>
