<template>
  <section class="detail-section">
    <h2 v-if="title" class="detail-title">{{ title }}</h2>
    <v-simple-table>
      <tbody>
        <tr v-for="key in keysFilteredEmptyRows" :key="key">
          <td class="text-left table-header">{{ $t(key) }}</td>
          <ProcessInfoCell v-for="(item, index) in showedItems" 
            :itemKey="key" 
            :itemValue="item[key]"
            :isReferenceFile="referenceFile === item.inputFileName"
            :key="`${key}-${index}`"
          />
        </tr>
      </tbody>
    </v-simple-table>
  </section>
</template>

<script>
import ProcessInfoCell from './ProcessInfoCell.vue';

export default {
  name: 'DetailSection',
  components: {
    ProcessInfoCell,
  },
  props: {
    title: {
      type: String,
    },
    //Pass array to show multiple columns
    listItem: {
      type: [Object, Array],
    },
    keysToShow: {
      type: Array,
    },
  },
  computed: {
    referenceFile() {
      const items = Array.isArray(this.listItem) ? this.listItem : [this.listItem];
      return items.find(item => item.isReference )?.inputFileName;
    },
    showedItems() {
      if(Array.isArray(this.listItem)){
        return this.listItem.map(item => this.formatItem(item));
      }
      return [this.formatItem(this.listItem)];
    },
    keysFilteredEmptyRows() {
      return this.keysToShow.filter(key => {
        return this.showedItems.some(item => item[key] !== null && item[key] !== undefined);
      });
    },
  },
  methods: {
    formatItem(item) {
      return Object.keys(item).reduce((obj, key) => {
        if (this.showTemplate(key, item[key])) {
          if (key === 'processType') obj[key] = item.joinType ? `${this.$t(item[key])} ${this.$t(`brackets${item.joinType}`)}` : this.$t(item[key]);
          else obj[key] = item[key];
        }
        return obj;
      }, {});
    },
    showTemplate(key, value) {
      if (!this.keysToShow.includes(key)) return false;
      if (key === 'variables') return value && value.length > 0;
      return true;
    },
  }
}
</script>

<style scoped lang="scss">
.detail-section {
  border-radius: 4px;
  ::v-deep .v-data-table__wrapper {
    border-radius: 10px;
    border: 1px solid #ddd;
    table {
      table-layout: fixed;
    }
  }
  .table-header {
    width: 250px;
    color: var(--fontColorTerciary);
  }
}
.detail-title {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary);
  padding-bottom: 20px;
}

.user-mail {
  color: var(--link) !important;
  text-decoration: underline;
}
</style>