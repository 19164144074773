<template>
  <div :style="{ 'max-height': maxHeight ? `${maxHeight}px` : `calc(100vh - ${headerContainer}px)` }" class="container-list">
    <HistoricList v-if="loadingParent" />
    <template v-else>
      <div v-for="(listItem, index) in paginatedData" :key="`${listItem.processId}-${index}`" class="card-list">
        <Card>
          <div v-if="loading && listItem.processId === selectedItem.id">
            <v-progress-linear color="var(--primary)" indeterminate rounded></v-progress-linear>
          </div>

          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header class="d-flex justify-space-between">
                <div class="headerContainer d-flex align-center gap">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <ProfileAvatar
                        :photoURL="listItem.user.photoURL"
                        :name="listItem.user.name || listItem.user.email"
                        :size="40"
                        v-on="on"
                      />
                    </template>

                    <span>{{ listItem.user.name || listItem.user.email }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-if="listItem.processTypeIcon" class="processTypeIcon"  >
                        <v-icon :color="'var(--white)'" size="20" v-on="on" class="processTypeIcon__v-icon">
                          {{ listItem.processTypeIcon }}
                        </v-icon>
                      </span>
                    </template>
                    <span>{{ $t(listItem.processType )}} 
                      <template v-if="listItem.joinType">
                        {{  $t(`brackets${listItem.joinType}`) }}
                      </template>
                    </span>
                  </v-tooltip> 

                  <span v-if="listItem.status === 'UPLOADING'" class="gifStatus" :class="listItem.status">
                    <img src="../../public/img/icons/uploadFile.gif" max-width="40px" max-height="40px" class="gifStatusImg" />
                    <div class="statusIcon"><span>{{ getProgress(listItem.processId) }}%</span></div>
                  </span>

                  <span v-else-if="listItem.status === 'GENERATING_REPORT'" class="gifStatus" :class="listItem.status">
                    <img src="../../public/img/icons/generatingReportFile.gif" max-width="40px" max-height="40px" class="gifStatusImg" />
                  </span>

                  <div v-else class="status">
                    <div class="statusIcon" :class="listItem.status">
                      <v-icon v-if="listItem.status === 'FINISHED'">mdi-check-circle</v-icon>
                      <v-icon v-else-if="listItem.status === 'FAIL'">mdi-alert-circle</v-icon>
                      <v-icon v-else-if="listItem.status === 'RUNNING'">mdi-cached</v-icon>
                      <v-icon v-else-if="listItem.status === 'VALIDATIONS'">mdi-alert</v-icon>
                      <span class="percentage" v-if="listItem.status === 'FINISHED'">100%</span>
                      <span class="percentage" v-else-if="listItem.status === 'FAIL'">0%</span>
                      <span class="percentage" v-else>{{ Math.round((listItem.numberReadPackages * 100) / listItem.totalNumberPackages) }}%</span>
                    </div>
                  </div>

                  <v-tooltip  max-width="200" bottom :disabled="!isTooltipActivated(`${listItem.processId}ProcessListItem`)">
                    <template v-slot:activator="{ on }">
                      <div 
                        v-on="on" 
                        class="headerItem flex-grow-1 text-truncate"
                        @mouseover="activateTooltip($event, `${listItem.processId}ProcessListItem`)"
                      >
                        {{ getFilesName(listItem) }} ({{ listItem.dateTitleFormatted }})
                      </div>
                    </template>

                    <span class="headerItem__tooltip">{{ getFilesName(listItem) }} ({{ listItem.dateTitleFormatted }})</span>
                  </v-tooltip>

                  <Actions
                    v-if="!hideActions && !['RUNNING', 'DELETING', 'UPLOADING', 'GENERATING_REPORT'].includes(listItem.status)"
                    :actions="cardActions(listItem.status)"
                    @action-selected="onActionSelected($event, listItem)"
                    class="justify-end order-last"
                  />
                </div>
              </v-expansion-panel-header>

              <v-expansion-panel-content :eager="true">
                <div class="table-container">
                  <DetailSection :title="showFileInfo && $t('processInfo') || ''" :keysToShow="infoProcessToShow" :listItem="listItem"/>
                </div>
                <div class="table-container mt-5" v-if="showFileInfo && Array.isArray(listItem.inputsInfo)">
                  <DetailSection :title="$t('fileInfo')" :keysToShow="infoFileToShow" :listItem="listItem.inputsInfo"
                  />
                </div>
                <div v-if="showDetailButton" class="d-flex justify-start mt-4">
                  <v-btn
                    color="primary"
                    depressed
                    @click="goToProcessDetail(listItem.processId)"
                    class="process-detail-btn"
                  >
                    <v-icon left>mdi-open-in-new</v-icon>
                    {{ $t('goToProcessDetail') }}
                  </v-btn>
                </div>

                <v-pagination v-if="paginationLength > 1" v-model="page" :length="paginationLength" circle class="mt-4"></v-pagination>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </Card>
      </div>
      <div v-if="showPagination" class="d-flex justify-center mt-4">
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          :total-visible="7"
          @input="onPageChange"
          circle
        />
      </div>
    </template>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import { tools } from "@/mixins/tools";
import { firebaseTools } from "@/mixins/firebase-tools.js";
import i18n from "@/plugins/i18n";
import { getDownloadURL, listAll } from "firebase/storage";
import HistoricList from "@/skeletonComponents/HistoricList";
import ProfileAvatar from '@/components/ProfileAvatar.vue';
import DetailSection from './DetailSection.vue';

export default {
  name: "ProcessList",
  components: {
    HistoricList,
    ProfileAvatar,
    DetailSection
  },
  data: () => ({
    selectedItem: undefined,
    keys: undefined,
    page: 1,
    paginationLength: undefined,
    pageElements: 10,
    parsedProcessesData: [],
    loading: false,
    currentPage: 1,
    infoProcessToShow: ['processType', 'processId', 'date', 'startTime','endTime','user'],
    infoFileToShow:["inputFileName", "delimiterColumns", "inputDefinitionName",  "selectedFilterNames", "outputDefinitionNames", "variables", "primaryKeyColumns", "finalColumns"],
    mapIcons: {
      JOIN: 'mdi-set-center',
      INNER: 'mdi-set-center', 
      OUTER: 'mdi-set-left-center',
      VALIDATE: 'mdi-check-decagram-outline',
      VALIDATE_TRANSFORM: 'mdi-crop',
    },
    tooltipActivated: [],
  }),
  props: {
    dataComponent: { type: Array },
    headerHeight: { type: Number },
    loadingParent: { type: Boolean, default: false },
    hidePagination: { type: Boolean, default: false },
    maxHeight: { type: Number, default: undefined },
    showFileInfo: { type: Boolean, default: true },
    hideActions: { type: Boolean, default: false },
    showDetailButton: { type: Boolean, default: false }
  },
  mixins: [tools, firebaseTools],
  computed: {
    ...mapState([
      "actualView",
      "dataView",
      "userLogged",
      "itemToSelect",
      "statusProgress",
      "processesPerPage",
      "currentProcessPage",
      "processData",
      "totalProcesses", 
      "actualGroup"
    ]),
    headerContainer() {
      return this.headerHeight + 20;
    },
    paginatedData() {
      const startPosition = (this.currentPage - 1) * this.processesPerPage;
      const endPosition = startPosition + this.processesPerPage;
      return this.parsedProcessesData.slice(startPosition, endPosition);
    },  
    totalPages() {
      const paginatedProcessesCount = this.parsedProcessesData.length > this.paginatedData.length ? this.parsedProcessesData.length : this.paginatedData.length;
      return Math.max(1, Math.ceil(paginatedProcessesCount / this.processesPerPage));
    },
    showPagination() {
      return !this.hidePagination && this.parsedProcessesData.length > this.processesPerPage;
    }
  },
  watch: {
    actualGroup: {
      deep: true,
      immediate: true,
      handler(newGroup, oldGroup) {
        if (newGroup?.id !== oldGroup?.id) {
          this.currentPage = 1;
          this.setCurrentProcessPage(this.currentPage);
        }
      }
    },
    totalPages(newVal) {
      if (this.currentPage > newVal) {
        this.currentPage = 1;
      }
    },
    dataComponent() {
      this.parsedProcessesData = [];
      this.setData();
    },
    page() {
      this.setData();
    },
    currentProcessPage: {
      immediate: true,
      handler(newPage, oldPage) {
        // We force a new subscription to obtain the data of the new page.
        if (newPage !== oldPage) this.subscribeOnSnapshotProcess();
      }
    },
    totalProcesses: { 
      immediate: true,
      handler(newTotal) { // Ensure that the pager is updated when the total changes
        if (newTotal > this.processesPerPage && !this.showPagination) {
          this.$nextTick(() => {
            this.$forceUpdate();
          });
        }
      }
    }
  },
  methods: {
    ...mapActions(["setItemToConfirmAction", "setItemToAddEdit", "setShowSnackBar", "setCurrentProcessPage"]),
    activateTooltip(event, ref) {
      const { target } = event;
      this.$set(
        this.tooltipActivated,
        ref,
        target?.offsetWidth < target?.scrollWidth
      );
    },
    isTooltipActivated(ref) {
      return this.tooltipActivated[ref];
    },
    setData() {
      for (const item of (this.dataComponent || [])) {
        const { status, user: { email, name, photoURL }, inputDescriptorInfo, numberReadPackages, totalNumberPackages, validationFileName, processType, joinType, ...element } = item;
       let startDate = new Date(element.startDate.seconds * 1000);   
        const inputsAttr = inputDescriptorInfo ? 'inputDescriptorInfo' : 'inputsInfo';
        const inputDef = item?.[inputsAttr];     
       const processTypeIcon = joinType ? this.mapIcons[joinType] : this.mapIcons[processType];
        let data = {
          processType: processType,
          joinType: joinType,
          processId: element.id,
          status,
          date: this.getFormattedDateField(startDate),
          dateTitleFormatted: this.getFormattedDate(
            new Date(element.startDate.seconds * 1000)
          ),
          startTime: this.getFormattedTime(startDate),
          endTime: this.getEndTime(element),
          user: {
            email,
            name,
            photoURL,
          },
          processTypeIcon,
          numberReadPackages,
          totalNumberPackages,
          validationFileName,
        };
        if(inputDef){
          data.inputsInfo = Array.isArray(inputDef) ? inputDef.map(input => this.parseInputData(input, inputsAttr, item)) : this.parseInputData(inputDef, inputsAttr, item)
        }

        this.parsedProcessesData.push({...data});
      }
    },
    parseInputData(input, inputsAttr, process ){
      const inputsAttrValue = inputsAttr === 'inputsDescriptorInfo' ? 'value': 'descriptorName';
      const { inputFileName,processType, outputDescriptorInfo, variables, delimiterInfo = delimiter } = process
      const fileName = inputFileName ?? input?.fileName?.substring(input.fileName.lastIndexOf('/') + 1);
      const delimiter = delimiterInfo ?? input.delimiter;

      const inputDefinitionName = input ? input[inputsAttrValue] : "--"; 

      let fileInfo = {
        inputFileName: fileName,
        delimiterColumns: delimiter ? i18n.t(delimiter) : "--",
        inputDefinitionName,
      }

      const filters = input?.selectedFilterNames ?? "--"; 

      if (processType === "VALIDATE"){
        fileInfo.selectedFilterNames= filters
      }
      if (processType === 'VALIDATE_TRANSFORM'){
        const outputsAttr = outputDescriptorInfo ? 'outputsDescriptorInfo' : 'outputsInfo';
        const outputsAttrValue = outputsAttr === 'outputsDescriptorInfo' ? 'value': 'descriptorName';
        const outputDef = process?.[outputsAttr];
        const outputDefinitionNames = outputDef ? outputDef.map(value => value[outputsAttrValue]) : "--";

        fileInfo.outputDefinitionNames= outputDefinitionNames
        fileInfo.variables = variables
        fileInfo.selectedFilterNames= filters
      }

      if (processType === 'JOIN'){
        const primaryKeyColumns = input?.primaryKeyColumns ?? "--"; 
        fileInfo.primaryKeyColumns = primaryKeyColumns.map(column => column.value)
        const selectedColumns = input?.selectedColumns ?? "--"; 
        fileInfo.finalColumns = selectedColumns
        fileInfo.isReference = input.isReference && process.joinType === 'OUTER'
      }
      return fileInfo;
    },
    goToProcessDetail(processId) {
      this.$router.push({
        name: 'ProcessHistory',
        query: {
          processId: processId
        }
      });
    },
    getFilesName(list) {
      if (!list.inputsInfo) return "--";
      const listOfNames = Object.values(list.inputsInfo).map(({inputFileName}) => inputFileName);
      return listOfNames.join(" - ");
    },
    getFormattedDate(date) {
      let dayOfStartDate = String(date.getDate()).padStart(2, "0");
      let yearOfStartDate = date.getFullYear();
      let today = new Date();
      let yearOfToday = today.getFullYear();
      let month = date.toLocaleString("default", { month: "long" });

      return (
        dayOfStartDate +
        " " +
        month.charAt(0).toUpperCase() +
        month.slice(1) +
        " " +
        (yearOfToday === yearOfStartDate ? "" : yearOfStartDate) +
        " - " +
        this.getFormattedTime(date)
      );
    },
    getFormattedDateField(date) {
      let dayOfStartDate = String(date.getDate()).padStart(2, "0");
      let month = date.toLocaleString("default", { month: "long" });
      let yearOfStartDate = date.getFullYear();
      return (
        dayOfStartDate +
        " " +
        month.charAt(0).toUpperCase() +
        month.slice(1) +
        " " +
        yearOfStartDate
      );
    },
    getEndTime(element) {
      let endTime = element.endDate;

      if (!endTime) {
        element.endTime = "--";
      } else {
        const dateToConvert = endTime.seconds ? endTime.seconds * 1000 : endTime;
        let endDate = new Date(dateToConvert);
        element.endTime = this.getFormattedTime(endDate);
      }

      return element.endTime;
    },
    getFormattedTime(date) {
      return (
        this.checkHour(date.getHours()) +
        ":" +
        this.checkHour(date.getMinutes()) +
        ":" +
        this.checkHour(date.getSeconds())
      );
    },
    checkHour(hour) {
      return hour < 10 ? "0" + hour : hour;
    },
    async onActionSelected(event, item) {
      this.selectedItem = this.dataComponent.find(
        (element) => element.id === item.processId
      );

      let copyItem = this.deepCopyFunction(this.selectedItem);
      const isJoinProcesss = copyItem.processType === 'JOIN';
      const baseDownloadPath = `${this.actualGroup.id}/output/${copyItem.id}/`;
      const endSubstringPath = isJoinProcesss ? '' : `${copyItem.id}.zip`;
      let path;

      switch (event.action) {
        case "deleteProcess":
          this.setItemToConfirmAction({
            title: i18n.t('deleteProcessTitle'),
            text: i18n.t('deleteProcessQuestion'),
            data: {
              documentId: copyItem.id,
              action: 'deleteProcess'
            }
          })
          break;
        case "download-validations":
          path = `${baseDownloadPath}${item.validationFileName}`;
          this.downloadFileFromStorage(path);
          break;
        case "download":
          path = `${baseDownloadPath}${endSubstringPath}`;
          if (!isJoinProcesss) {
            this.downloadFileFromStorage(path);
          } else {
            const folderRef = this.createStorageReference(baseDownloadPath);
            const filesInFolder = await listAll(folderRef);
            filesInFolder.items.forEach(fileRef => {
              const filePath = fileRef.fullPath;
              this.downloadFileFromStorage(filePath);
            });
          }
          break;
      }
    },
    downloadFileFromStorage(path) {
      this.setShowSnackBar({
        color: 'info',
        msg: i18n.t('downloadingFile'),
        icon: "mdi-information",
      })

      this.loading = true;
      const fileRef = this.createStorageReference(path);

      this.downloadStorageFile(fileRef, path).finally(() => {
        this.loading = false;
      });
    },
    cardActions(status) {
      return this.setActions(this.actualView.componentActions).filter((a) =>
        a.status.includes(status)
      );
    },
    getProgress(processId) {
      return this.statusProgress[processId] || 0;
    },
    onPageChange(newPage) {
      if (newPage !== this.currentProcessPage) {
        this.setCurrentProcessPage(newPage);

        // We force a new subscription to obtain the data of the new page.
        // TODO for now is not necessary to resubscribe
        // this.subscribeOnSnapshotProcess();
      }
    }
  },
  created() {
    // Ensure that currentPage is synchronized when creating the component
    this.currentPage = this.currentProcessPage;
  },
  mounted() {
    this.setData();
  },
};
</script>

<style lang="scss" scoped>
.container-list {
  overflow: auto;
  padding: 6px;
  margin-top: -6px;

  .card-list:not(:last-child) {
    margin-bottom: 20px;
  }

  .card-list:last-child {
    margin-bottom: 10px;
  }

  .v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)>.v-expansion-panel--active {
    border-radius: 10px;
  }
  .v-expansion-panel::before {
    box-shadow: none;
  }
}

::v-deep {
  .card-after-header {
    display: flex;
  }
}

.status {
  color: var(--fontColorTerciary);
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 10px;

  >div {
    display: flex;
    flex-wrap: wrap;

    span {
      line-height: 14px;
    }
  }

  .v-btn {
    border-color: var(--borderGray);
    color: var(--fontColorSecondary);
    text-transform: none;
    font-weight: 400;

    .v-icon {
      font-size: 0.9rem;
    }
  }

  .v-icon {
    padding: 5px;
    font-size: 24px;
  }

  .statusIcon {
    border-radius: 5px !important;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .percentage {
      font-weight: 700;
      padding-right: 5px;
    }

    &.FAIL,
    &.STOPPED {
      border: 1px solid var(--red);
      color: var(--red);

      .v-icon {
        color: var(--red);
      }
    }

    &.FINISHED {
      border: 1px solid var(--green);
      color: var(--green);

      .v-icon {
        color: var(--green);
      }
    }

    &.VALIDATIONS {
      border: 1px solid var(--orange);
      color: var(--orange);

      .v-icon {
        color: var(--orange);
      }
    }

    &.RUNNING {
      border: 1px solid var(--blue);
      color: var(--blue);

      .v-icon {
        color: var(--blue);
        animation: spin-animation 2s linear infinite;
      }
    }
  }

  @keyframes spin-animation {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
}

.gifStatus {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-weight: 500;

  &.UPLOADING {
    border: 1px solid var(--secondary);
    color: var(--secondary);
  }

  &.GENERATING_REPORT {
    border: 1px solid var(--primary);
  }
}

.gifStatusImg {
  width: 28px;
}

ol,
ul {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

.table-container {
  margin-top: 10px;
  border-radius: 10px;
  overflow: hidden;

  tr .text-left {
    color: var(--fontColorTerciary) !important;
    font-weight: 400;
  }

  tr td {
    width: 50%;
    color: var(--fontColor);
  }
}

.headerContainer {
  max-width: calc(100% - 32px); // 16px + 16px = expand icon + header right padding
}

.headerItem {
  font-size: 18px;
  &__tooltip {
    word-wrap: break-word;
  }
}

.gap {
  gap: 12px;
  color: var(--fontColor);
}
.processTypeIcon{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px; 
  height: 28px;
  background-color: var(--secondary); 
  border-radius: 50%;
  padding: 10px;
}
.process-detail-btn{
  border-radius: 20px;
  font-size: 14px;
}
</style>
